<template>
	<div class="bd-nftpage bd-container-padding">
		<div class="tf-section tf-item-details style-2 ">
			<div class="themesflat-container">
				<div class="row">
					<div class="col-xl-4 col-md-12">
						<div class="content-left">
							<div class="media">
                <img v-show="load_picture" :src="nft.picture" class="bd-nftpage-img" @load="load_picture = true">
                <img v-show="!load_picture" class="bd-nftpage-img bd-light" src="/assets/images/bluediamonds/loader-light.gif" />
                <img v-show="!load_picture" class="bd-nftpage-img bd-dark" src="/assets/images/bluediamonds/loader-dark.gif" />
							</div>
							
							<div class="content-right bd-nftpage-tabs" id="activities-box">
								<div class="flat-tabs themesflat-tabs">
									<ul class="menu-tab tab-title">
										<li class="item-title" :class="{active: current_nft_page_tab == 'price_history'}" @click="switch_nft_page_tab('price_history')">
											<span class="inner" style="font-size: 20px">Price history</span>
										</li>
										<li class="item-title" :class="{active: current_nft_page_tab == 'details'}" @click="switch_nft_page_tab('details')">
											<span class="inner" style="font-size: 20px">Details</span>
										</li>
										<li class="item-title" :class="{active: current_nft_page_tab == 'about'}" @click="switch_nft_page_tab('about')">
											<span class="inner" style="font-size: 20px">About</span>
										</li>
									</ul>
								</div>
							</div>
							<!-- price_history -->
							<div class="bd-nftpage-collection-details" v-show="current_nft_page_tab == 'price_history'">
								<StatGraph class="graph_stat_info" :chart-data="nft.price_history" :options="options"></StatGraph>
							</div>
							<!-- desription -->
							<div class="bd-nftpage-collection-details" v-show="current_nft_page_tab == 'about'">
								<div class="meta-item-details">
									<div class="item-style-2 item-details" style="width: 100%">
										<h6><router-link :to="'/collection-page/'+nft.collection.symbol">{{ nft.collection.name }}</router-link></h6>
										{{ nft.collection.description }}
									</div>
								</div>
							</div>
							<!-- details -->
							<div class="content-right bd-nftpage-product-details" v-show="current_nft_page_tab == 'details'">
								<div class="meta-item-details">
									<div class="row">
										<div class="col-md-12">
											<div class="item-style-2 item-details" style="width: 100%">
												<ul class="list-details">
													<li><span>Mint address : </span><h6><a :href="'https://solscan.io/token/' + nft.mint_address" target="_blank">{{ nft.mint_address | reduce_text(4,4)}}</a></h6></li>
													<li><span>Artist Royalties : </span><h6>{{ nft.collection.royalties }}%</h6> </li>
													<li><span>Transaction fees : </span><h6>{{ nft.collection.transaction_fees }}%</h6> </li>
													<li>
														<span>Owned by : </span>
														<h6>
															<router-link :to="'/user/' + nft.owner.wallet" v-if="nft.owner != undefined && nft.owner != null && nft.owner.wallet !== null && nft.owner.wallet != ''">{{ nft.owner.wallet | reduce_text(4,4) }}</router-link>
															<img class="bd-nftpage-ownedby" :src="route_bo+'/storage/'+nft.owner.picture" v-if="nft.owner != undefined && nft.owner.picture !== null && nft.owner.picture != ''" alt="">
															<img class="bd-nftpage-ownedby"  src="/assets/avatar/default_avatar.png" v-else alt="">
														</h6> 
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-8 col-md-12">
						<div class="content-right">
							<div class="sc-item-details">
								<div class="bd-nftpage-nftcontainer">
									<h2 class="bd-title">{{ nft.name }}
										<span class="bd-button-filter-collection-display bd-desktop" @click="$root.rotate($event, 'nft_page');load_nft_data()" style="cursor: pointer;">
											<i class="fas fa-sync-alt bd-button-icon bd-button-icon-rotation" style="font-size: 16px;"></i>
										</span>
									</h2>
									<h6> <router-link :to="'/collection-page/'+nft.collection.symbol" class="color-primary">{{ nft.collection.name }}</router-link> </h6>
									<div class="bd-nftpage-nftstats">
										
											<span class="fa fa-eye"></span> 
											<span class="number">{{ nft.views }}</span>
											<LikeButtonNft :nft="nft" :collection_page="false"></LikeButtonNft>
										
									</div>
								</div>
								
								<div class="row bd-nftpage-row-price">
									<div class="col-md-6 bd-nftpage-price center" v-if="nft.listed == 1">◎ {{ nft.price }}</div>
									<div class="col-md-6 bd-nftpage-price center" v-else>
										Not listed
									</div>
									<div class="col-md-6 bd-nftpage-buy-button">
										<span v-show="nft.highest_offer !== null">
											<span class="bd-nftpage-lastprice">
												<span>Highest offer:</span> ◎ {{ nft.highest_offer }}
											</span>
										</span>
										<span v-show="nft.last_price !== null && nft.highest_offer === null">
											<span class="bd-nftpage-lastprice">
												<span>Last price:</span> ◎ {{ nft.last_price }}
											</span>
										</span>
									</div>
								</div>
								
								<div class="row bd-nftpage-row-options bd-desktop" v-if="nft.owner.wallet != $root.wallet_address || $root.connected !== 1">
									<div class="col-md-6 bd-nftpage-buy-button" v-if="nft.listed == 1">
										<div class="bd-button bd-button-primary" @click="$root.buy_nft(nft)" v-if="$root.connected === 1">Buy now</div>
										<div class="bd-button bd-button-primary" @click="$root.$emit('show_modal',['connect_wallet',true])" v-else>Connect Wallet</div>
									</div>
									<div class="col-md-6 bd-nftpage-buy-button" v-if="nft.listed == 1"><div class="bd-button bd-button-secondary" v-if="$root.connected === 1"  @click="$root.$children[0].$data.modal.make_offer = {show_modal:true,nft:nft,floor_price:nft.collection.floor_price}" >Make offer</div></div>
								</div>
								
								<div class="row bd-nftpage-row-options bd-desktop" v-if="nft.owner.wallet == $root.wallet_address && $root.connected === 1">
									<!-- listing -->
									<div class="col-md-6 bd-nftpage-buy-button" v-if="nft.listed === null && nft.listing_possible !== false && nft.owner.wallet == $root.wallet_address && $root.connected === 1 && loading_action === false">
										<div class="bd-button bd-button-primary" @click="$root.$children[0].$data.modal.list_nft = {show_modal : true,nft:nft}">List</div>
									</div>
									
									<!-- cancel listing -->
									<div class="col-md-6 bd-nftpage-buy-button" v-if="nft.listed == 1 && loading_action === false">
										<div class="bd-button bd-button-primary" @click="$root.$children[0].$data.modal.list_cancel_nft = {show_modal : true,nft:nft}">Cancel listing</div>
									</div>
									
									<!-- loader -->
									<div class="col-md-6 bd-nftpage-buy-button" v-if="loading_action === true">
										<div class="bd-button bd-button-primary">
											<img style="width:8%" src="/assets/images/loading.svg"/>
										</div>
									</div>
								</div>
								
								<!--
								<div class="meta-item-details">
									<div class="item-style-2" v-show="nft.listed !== null">
										<div class="item meta-price">
											<span class="heading">Buy now price</span>
											<div class="price">
												<div class="price-box">
													<h5>◎ {{ nft.price }}</h5>
													
												</div>
											</div>
										</div>
										<a href="#" @click="buy_nft(nft)" class="sc-button loadmore style bag fl-button pri-3 bd-nftpage-buynow-button"><span>Buy now for ◎ {{ nft.price }}</span></a>
									</div>
									<div class="item-style-2">
										<div class="item meta-price" v-show="nft.highest_offer == undefined && nft.last_price == undefined" style="opacity: 0">
											<span class="heading">fill</span>
										</div>
										<div class="item meta-price" v-show="nft.highest_offer != undefined || nft.last_price != undefined">
											<span v-if="nft.highest_offer != undefined" class="heading">Highest offer</span>
											<span v-else-if="nft.last_price != undefined" class="heading">Last price</span>
											<div class="price">
												<div class="price-box">
													<h5 v-if="nft.highest_offer != undefined">◎ {{ nft.highest_offer }}</h5>
													<h5 v-else-if="nft.last_price != undefined">◎ {{ nft.last_price }}</h5>
												</div>
											</div>
										</div>

										<a href="#" data-toggle="modal" data-target="#popup_bid" class="sc-button loadmore style bag fl-button pri-3 bd-nftpage-makeoffer-button"><span>Make offer</span></a>
									</div>
								</div>
								-->
								
								
								<div class="meta-item-details">
									
									<div class="bd-nftpage-attributes-list">
										<h3>BD Rarity ranking : <span class="color-primary">{{ nft.ranking }}</span> <span class="bd-nftpage-collection-supply">/ {{ nft.collection.supply }}</span></h3><br/><br/><br/>
										<div class="row">
											<div class="col-md-4" v-for="(attribute, key) in nft.attributes" :key="key">
												<div class="item-style-2 item-details bd-nftpage-attributes">
													<div class="list-details">
														<h6>
															{{ attribute.attribute }}
															<span class="fa fa-gem" style="color: var(--primary); margin-right: 5px;" v-if="attribute.diamonds >= 1"></span>
															<span class="fa fa-gem" style="color: var(--primary); margin-right: 5px;" v-if="attribute.diamonds >= 2"></span>
														</h6><br/>
														<span class="bd-attribute">
															{{ attribute.value }}
														</span><br/>
														<span class="bd-attribute-rarity">{{ attribute.rarity }}% have this trait</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								
								
							</div>
						</div>
					</div>
					
					<div class="col-xl-12 col-md-12 content-right" style="padding-left: 15px;" id="activities-box">
						<div class="flat-tabs themesflat-tabs">
							<ul class="menu-tab tab-title">
								<li class="item-title" :class="{active: current_activity_tab == 'all'}" @click="switch_activity_tab('all')">
									<span class="inner">All activities</span>
								</li>
								<li class="item-title" :class="{active: current_activity_tab == 'listing'}" @click="switch_activity_tab('listing')">
									<span class="inner">Listing</span>
								</li>
								<li class="item-title" :class="{active: current_activity_tab == 'offers'}" @click="switch_activity_tab('offers')">
									<span class="inner">Offers</span>
								</li>
								<li class="item-title" :class="{active: current_activity_tab == 'sales'}" @click="switch_activity_tab('sales')">
									<span class="inner">Sales</span>
								</li>
							</ul>
							<div class="row bd-nftpage-activity-titles bd-desktop">
								<div class="col-md-2"> Activity </div>
								<div class="col-md-2"> Price </div>
								<div class="col-md-2"> Seller </div>
								<div class="col-md-2"> Buyer </div>
								<div class="col-md-2"> Time </div>
								<div class="col-md-2"> Marketplace </div>
								
							</div>
							
							<NftActivity :activity="activity" v-for="(activity, key) in activities" :key="key"></NftActivity>
						</div>
					</div>

					<div class="col-xl-12 col-md-12 content-right" style="padding-left: 15px;" id="activities-box" v-show="Object.entries(more_from.collection).length > 0">
						<div class="row" style="margin-bottom: 10px">
              <h2>More from this collection</h2>
            </div>
						<div class="row">
              <div class="box-epxlore" id="nfts-box">
                <NftCard :nft="nft" :nft_per_line="'four'" v-for="(nft, key) in more_from.collection" :key="key"></NftCard>
                <div v-if="modulo_collection >= 1" class="sc-card-product bd-empty-nftcard four-per-line"></div>
                <div v-if="modulo_collection >= 2" class="sc-card-product bd-empty-nftcard four-per-line"></div>
                <div v-if="modulo_collection >= 3" class="sc-card-product bd-empty-nftcard four-per-line"></div>
                <div v-if="modulo_collection >= 4" class="sc-card-product bd-empty-nftcard four-per-line"></div>
              </div>
            </div>
					</div>

					<div class="col-xl-12 col-md-12 content-right" style="padding-left: 15px;" id="activities-box" v-show="Object.entries(more_from.owner).length > 0">
						<div class="row" style="margin-bottom: 10px">
              <h2>More from this owner</h2>
            </div>
						<div class="row">
              <div class="box-epxlore" id="nfts-box">
                <NftCard :nft="nft" :nft_per_line="'four'" v-for="(nft, key) in more_from.owner" :key="key"></NftCard>
                <div v-if="modulo_owner >= 1" class="sc-card-product bd-empty-nftcard four-per-line"></div>
                <div v-if="modulo_owner >= 2" class="sc-card-product bd-empty-nftcard four-per-line"></div>
                <div v-if="modulo_owner >= 3" class="sc-card-product bd-empty-nftcard four-per-line"></div>
                <div v-if="modulo_owner >= 4" class="sc-card-product bd-empty-nftcard four-per-line"></div>
              </div>
            </div>
					</div>
				</div>
			</div>
		</div>
		<!-- /tf item details -->

		
	</div>
</template>

<script>

import axios from 'axios';
import StatGraph from '@/components/StatGraph';
import LikeButtonNft from '@/components/LikeButtonNft.vue';
import NftActivity from '@/components/NftActivity.vue';
import NftCard from '@/components/NftCard.vue';
import $ from 'jquery';

let config_axios = {
	headers: {
		'Content-Type': 'application/json;',
	}
}

const options = {
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    animateRotate: false
  },
  legend: {
    display: false
  }
}

export default {
  name: 'Nft',
  
  components: {
		StatGraph,
		LikeButtonNft,
		NftActivity,
    NftCard,
  },
  props: {
  },
  data: function () {
	
	return {
		
		route_bo: process.env.VUE_APP_ROOT_BO,
		activities: {},
		current_activity_tab: 'all',
		current_nft_page_tab: 'price_history',
		reload_activate: false,
		count_activities: 0,
		loading_action: false,
    load_picture: false,
		filters: {

			number_per_page: 10,
			types: {
				sales: true,
				listing: true,
				offers: true,
			},
		},

		nft: {
			price_history: {
				labels: ['Shrimp (1)', 'Crab (2-4)', 'Octopus (5-9)', 'Fish (10-19)', 'Dolphin (20-29)', 'Shark (30-49)', 'Whale (50-99)', 'Humpback (100+)'],
				datasets: [
					{
						label: 'Unlisted',
						backgroundColor: ['#0083ff', '#0083ff', '#0083ff', '#0083ff','#0083ff','#0083ff', '#0083ff', '#0083ff'],
						data: [0,0,0,0,0,0,0,0,0]
					},
					{
						label: 'Listed',
						backgroundColor: ['#0083ff', '#0083ff', '#0083ff', '#0083ff','#0083ff','#0083ff', '#0083ff', '#0083ff'],
						data: [0,0,0,0,0,0,0,0,0]
					},
				]
			},
      picture: '',
      title: '',
      views: 0,
      likes: 0,
      price: 0,
      highest_offer: 0,
      owner: {
      },
      collection: {
      },
      attributes: [
      ],
		},
		options,
    more_from : {

      collection : {},
      owner : {},

    }
	}
  },
  computed : {

    modulo_owner: function() {

      if(this.more_from == undefined)
        return 0;

      if(this.more_from.owner == undefined)
        return 0;

      if(Object.entries(this.more_from.owner).length == 0)
        return 0;

      return parseInt(Object.entries(this.more_from.owner).length % 4);


    },

    modulo_collection: function() {

      if(this.more_from == undefined)
        return 0;

      if(this.more_from.collection == undefined)
        return 0;

      if(Object.entries(this.more_from.collection).length == 0)
        return 0;

      return parseInt(Object.entries(this.more_from.collection).length % 4);


    },

  },
	methods: {
	
		switch_activity_tab: function(tab) {
		
			this.current_activity_tab = tab;
			
			if(tab == 'all') {
				
				this.filters.types = {
					sales: true,
					listing: true,
					offers: true,
				};
			}
			
			if(tab == 'listing') {
				
				this.filters.types = {
					sales: false,
					listing: true,
					offers: false,
				};
			}
			
			if(tab == 'offers') {
				
				this.filters.types = {
					sales: false,
					listing: false,
					offers: true,
				};
			}
			
			if(tab == 'sales') {
				
				this.filters.types = {
					sales: true,
					listing: false,
					offers: false,
				};
			}
			
			this.fetch_activities(false);
		},
	
		switch_nft_page_tab: function(tab) {
		
			this.current_nft_page_tab = tab;
		},

		fetch_activities(scroll = false) {
		
			this.reload_activate = true;
			
			var filters = this.filters; 
			
			if(scroll === false)
				delete filters.number_per_page;
			
			var $this = this;

			var config = config_axios;
			
			var mint_address = this.$route.params.mint_address;

			axios.post(process.env.VUE_APP_ROOT_API + '/marketplace/nft/'+mint_address+'/activities', filters, config).then(function (activities) {
				
				if(scroll === true)
					$this.activities = $this.activities.concat(activities.data.activities);
				else {
				
					$this.activities = activities.data.activities;
					filters.number_per_page = 10;
				}
		
				$this.count_activities = activities.data.count;
				
				$this.reload_activate = false;

			});
		},
		
		scroll() {
		
			window.onscroll = () => {
			
				let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight >= document.getElementById('activities-box').offsetHeight

				if (bottomOfWindow && this.reload_activate === false && this.count_activities > this.filters.number_per_page) {
				
					this.fetch_activities(true);
					this.filters.number_per_page += 10;
				}
			}
		},

    like_nft : function (){

      var component_vue = this;
      
      if(component_vue.$root.connected == 0) {
        component_vue.$root.$emit('show_modal',['connect_wallet',true]);

        component_vue.$root.$on('connection_wallet_accept',() => {
          var config = config_axios;

          config.nft_id = component_vue.nft.id
          config.wallet_address = component_vue.$root.wallet_address

          axios.post(process.env.VUE_APP_ROOT_API+'/marketplace/nft/like_nft', config).then(function(nft) {

            if(Object.prototype.hasOwnProperty.call(nft.data, "likes")) {
              component_vue.nft.liked = nft.data.likes;
              component_vue.$forceUpdate;
            }

          });
        });
      }
      else {

        var config = config_axios;

        config.nft_id = component_vue.nft.id
        config.wallet_address = component_vue.$root.wallet_address

        axios.post(process.env.VUE_APP_ROOT_API + '/marketplace/nft/like_nft', config).then(function (nft) {

          if (Object.prototype.hasOwnProperty.call(nft.data, "likes")) {
            component_vue.nft.liked = nft.data.likes;
            component_vue.$forceUpdate;
          }

        });

      }

    },

    load_nft_data : function(){
      // this.$root.loading(true);

      var $this = this;
      var mint_address = this.$route.params.mint_address;

      var request_1 = axios.get(process.env.VUE_APP_ROOT_API+'/marketplace/nft/'+mint_address, config_axios);

      request_1.then(function(nft) {

        $this.nft = nft.data;
      });

      var request_2 = axios.get(process.env.VUE_APP_ROOT_API+'/marketplace/nft/more_from/collection_id/'+mint_address, config_axios);

      request_2.then(function(nfts) {

        $this.more_from.collection = nfts.data;

      });

      var request_3 = axios.get(process.env.VUE_APP_ROOT_API+'/marketplace/nft/more_from/owner/'+mint_address, config_axios);

      request_3.then(function(nfts) {

        $this.more_from.owner = nfts.data;

      });



      axios.all([request_1, request_2, request_3])
      .then(axios.spread((...args) => {
      }))
      .then(() => {

        // this.$root.loading(false);

      });

      this.fetch_activities();

    }
	
  },
  created: function() {
	
    this.load_nft_data();
  },
  mounted () {
    this.scroll();

    var component_vue = this;

    component_vue.$root.$on('change_buy_nft',function(nft_id){

      component_vue.load_nft_data();

		});

    component_vue.$root.$on('change_list_nft',function(nft_id){

      component_vue.load_nft_data();

		});

    component_vue.$root.$on('change_cancel_nft',function(nft_id){

      component_vue.load_nft_data();

		});
		
    component_vue.$root.$on('loading_action_on_nft',function(args) {

      if(args.nft_id === component_vue.nft.id) {
        component_vue.loading_action = args.loading;
        component_vue.$forceUpdate();
      }
    });
		
  },
  watch: {
    '$route.params.id': {
      handler: function(search) {

        this.load_nft_data();
        this.load_picture = false;
        $('html,body').scrollTop(0);
      },
      deep: true,
      immediate: true
    }
  },
}
</script>

