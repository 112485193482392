<template>
	<div class="bd-activity bd-activitynft bd-shadow-on-hover" style="display: block;">
		<div class="row">
			<div class="col-md-2">
				<span v-show="activity.type == 24" class="bd-activity-type">
					<span class="fa fa-cart-arrow-down bd-activity-icon color-primary"></span> 
					<span class="bd-activity-name"><span class="color-primary">Sale</span> <span style="font-weight: 300">(Buy now)</span></span>
				</span>
				<span v-show="activity.type == 25" class="bd-activity-type">
					<span class="fa fa-cart-arrow-down bd-activity-icon color-primary"></span> 
					<span class="bd-activity-name"><span class="color-primary">Sale</span> <span style="font-weight: 300">(Offer)</span></span>
				</span>
				<span v-show="activity.type == 23" class="bd-activity-type">
					<span class="fa fa-hands-helping bd-activity-icon color-primary"></span> 
					<span class="bd-activity-name color-primary">Offer</span>
				</span>
				<span v-show="activity.type == 21" class="bd-activity-type">
					<span class="fa fa-tag bd-activity-icon color-primary"></span> 
					<span class="bd-activity-name color-primary">Listing</span>
				</span>
				<span v-show="activity.type == 22" class="bd-activity-type">
					<span class="fa fa-tag bd-activity-icon color-primary"></span> 
					<span class="bd-activity-name color-primary">Cancel listing</span>
				</span>
			</div>
			<div class="col-md-2">
				<span class="bd-activity-text bd-activity-price" v-show="activity.price !== null">{{ activity.price }} SOL</span>
			</div>
			
			<div class="col-md-2 bd-activity-user">
				<router-link :to="'/user/'+activity.seller.wallet" v-if="activity.seller !== null">
					<img :src="this.$root.avatar(activity.seller)"> {{ activity.seller.wallet | reduce_text(3,3) }}
				</router-link>
			</div>
			<div class="col-md-2 bd-activity-user">
				<router-link :to="'/user/'+activity.buyer.wallet" v-if="activity.buyer !== null">
					<img :src="this.$root.avatar(activity.buyer)"> {{ activity.buyer.wallet | reduce_text(3,3) }}
				</router-link>
			</div>
			<div class="col-md-2">
				<span class="bd-activity-text">{{ activity.date | moment("MMM Do YY, h:mm a") }}</span>
			</div>
			<div class="col-md-2">
				<span class="bd-activity-text" v-show="activity.marketplace_id === null">Blue Diamonds</span>
				<span class="bd-activity-text" v-show="activity.marketplace_id == 8">Solanart</span>
				<span class="bd-activity-text" v-show="activity.marketplace_id == 9">Magic Eden</span>
			</div>
			
		</div>
	</div>
</template>

<script>

export default {
name: 'NftActivity',
	props: {
		activity: {},
	},
}
</script>

